<template>
  <v-container fluid>
    <h1 class="text-h4 font-weight-bold mb-4">{{ categoryTitle }}</h1>

    <v-row>
      <v-col v-for="article in paginatedArticles" :key="article.id" cols="12" md="3" sm="6">
        <v-card
          height="500"
          color="indigo lighten-5"
          class="mx-auto d-flex flex-column justify-space-between"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="article.featuredImage"
          />
          <v-card-subtitle class="text-uppercase pt-5 px-6 pb-0">
            {{ article.createdAt | dateFormatted }}
          </v-card-subtitle>
          <v-card-text class="py-0 px-2 text-card">
            <v-card-title>{{ article.headline }}</v-card-title>
            <v-card-subtitle>{{ article.caption }}</v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined color="secondary" :to="`/read-compliance/${article.slug}`">
              Ver mais
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-pagination
        v-model="currentPage"
        :length="pageCount"
        color="primary"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import { findActiveExternalCompliances } from "../../services/compliance-service";
import { AllCategory } from "../../services/compliance-category-service"; 

export default {
  name: "CategoryArticles",
  data() {
    return {
      articles: [],
      filteredArticles: [],
      categoryTitle: "", 
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredArticles.length / this.itemsPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredArticles.slice(start, end);
    },
  },
  async created() {
    this.articles = await findActiveExternalCompliances();

    const categoryId = parseInt(this.$route.params.id);
    this.filteredArticles = this.articles
      .filter((article) => article.category.id === categoryId)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 

    const categories = await AllCategory();
    const category = categories.find((cat) => cat.id === categoryId);
    this.categoryTitle = category ? category.title : "Categoria não encontrada";
  },
};
</script>

<style scoped>
.text-card {
  color: rgba(0, 0, 0, 0.7);
}
</style>
